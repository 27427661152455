body{
  text-align: right;
}
a {
  text-decoration: none;
}

/* Default width for all devices */
.App {
  padding-top: 100px;
  width: 100%;
  margin: 0 auto;
  direction: rtl;
}

/* Width for tablet devices */
@media (min-width: 768px) {
  .App {
    width: 80%;
  }
}

/* Width for desktop devices */
@media (min-width: 992px) {
  .App {
    width: 70%;
  }
  .mainImage{
    width: 50% !important;
    float:left;
    padding:20px;
  }
}

.blogText{
  text-align: justify;
}
.center{
  text-align: center;
  padding: 10px;
}
.tags{
  text-align: center;
  padding:10px;
  border-radius: 10%;
  color:#000;
  border:1px solid;
}
.tags.active{
  background: #eee;
  color: #00f;
}
.blogFull{
  padding-top:20px;
}